import React from "react";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Link from '@material-ui/core/Link';
import GithubIcon from '@material-ui/icons/GitHub';
import "../styles/Footer.css"

function Footer() {
  return (
    <div className='footer'>
      <div className='socialMedia'>
        <Link rel="stylesheet" href="https://www.linkedin.com/in/mingun-choi/" >
          <LinkedInIcon/>
        </Link>
        <Link rel="stylesheet" href="https://github.com/devRyanChoi">
          <GithubIcon/>
        </Link>        
      </div>
      <p>&copy; 2023 mingunryanchoi.com</p> 
      </div>
  )
}
export default Footer