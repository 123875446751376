import React from "react";
import LinkedIcon from '@material-ui/icons/LinkedIn';
import GithubIcon from '@material-ui/icons/GitHub';
import Link from '@material-ui/core/Link';
import "../styles/Home.css" 


function Home() {
  return <div className='Home'>
    <div className='about'>
      <h2>Hi, My name is  Ryan  choi </h2>
      <div className='prompt'>
        <h5> A software developer with a passion for learning and crearting. </h5>
        <p>Email: ryanchoi100826@gmail.com</p>
        <p>Mobile: +1-647-212-5115</p>

        {/* <div>
          <Link rel="stylesheet" href="https://www.linkedin.com/in/mingun-choi/" >
        <LinkedIcon/>
        </Link>
        <Link rel="stylesheet" href="https://github.com/devRyanChoi">
          <GithubIcon/>
          </Link>
        </div> */}
        
      </div>
    </div>
    <div className="skills"> 
      <h1> Skills </h1>
      <ol className="list">
          <li className='item'>
            <h4> Programming Languages </h4>
            <span>JavaScript, TypeScript, Java, HTML, CSS  </span>
          </li>
          <li className='item'>
            <h4> Web Development Technologies </h4>
            <span>React, Node.js, Express.js, Ajax, EJS, jQuery, Bootstrap, Material-Ui, Spring</span>
          </li>
          <li className='item'>
            <h4> Testing</h4>
            <span>
              Cypress, Rspec, Jest, Mocha, Chai
            </span>
          </li>
          <li className='item'>
            <h4> DataBases </h4>
            <span>
              SQL Server, MongoDB
            </span>
          </li>
          <li className='item'>
            <h4> Version Control </h4>
            <span>
              Git
            </span>
          </li>
          <li className='item'>
            <h4> Cloud Technology </h4>
            <span>
              AWS
            </span>
          </li>
      </ol>
      
    </div>
  </div>;
}

export default Home