import React from "react";
import {
  VerticalTimeline, 
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import 'react-vertical-timeline-component/style.min.css';
import SchoolIcon from "@material-ui/icons/School"
import WorkIcon from "@material-ui/icons/Work"
import "../styles/Experience.css"

function Experience() {
  return (
  <div className="experience">
    <VerticalTimeline lienColor='#3e497a'>
      <VerticalTimelineElement
      className='vertical-timeline-element--education'
      date= "Mar 2008 - Feb 2011"
      iconStyle={{background: "#3e497a", color:"#fff"}}
      icon={<SchoolIcon/>}
      > 
      <h3 className='vertical-timeline-element-title'>
        Dankook University
      </h3>
      <h4 className='vertical-timeline-element-title'>
        Seoul, South Korea
      </h4>
      <p>Bachelor of Engineering</p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
      className='vertical-timeline-element--work'
      date= "Jun 2012 - Oct 2016"
      iconStyle={{background: "#e9d", color:"#fff"}}
      icon={<WorkIcon/>}
      > 
      <h3 className='vertical-timeline-element-title'>
      Assistant IT manager - Knorr Bremse Korea
      </h3>
      <h4 className='vertical-timeline-element-title'>
        Seoul, Korea
      </h4>
      <p> - Troubleshoots hardware and software issues in a timely and prioritized manner. <br></br> - Update servers to reflect current staffing, equipment and data.<br></br> - Maintain and updates documentation of the organization's current IT infrastructure.</p>
    </VerticalTimelineElement>
    
    <VerticalTimelineElement
      className='vertical-timeline-element--work'
      date= "Oct 2016 - Jan 2018"
      iconStyle={{background: "#e9d", color:"#fff"}}
      icon={<WorkIcon/>}
      > 
      <h3 className='vertical-timeline-element-title'>
      Full Stack Developer - Cozyez
      </h3>
      <h4 className='vertical-timeline-element-title'>
        Seoul, Korea
      </h4>
      <p> - Composed storyboard using Figma/PowerPoint and provided HTML/CSS/JS work assistance for Web Development. <br></br> - Implemented authentication in Node.js with MongoDB, brypt, and JWT, web Tokens with cook.<br></br> - Developed a Chrome extension to add popup user interface.</p>
    </VerticalTimelineElement>

    <VerticalTimelineElement
      className='vertical-timeline-element--work'
      date= "Jan 2018 - Jun 2020"
      iconStyle={{background: "#e9d", color:"#fff"}}
      icon={<WorkIcon/>}
      > 
      <h3 className='vertical-timeline-element-title'>
        Full Stack developer - B&B Partners
      </h3>
      <h4 className='vertical-timeline-element-title'>
        Seoul, Korea
      </h4>
      <p> - Supported with the creation and delpoyment of landing pages, membership portals for client. <br></br> - Collaborated with the development team to define and integrate REST API using Node.js, ensuring seamless data exchange and optimal user experience. <br></br> - Developed the web-based applications using front end technologies such as HTML, CSS, JavaScript and React.
      </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
      className='vertical-timeline-element--work'
      date= "Mar 2022 - Jul 2022"
      iconStyle={{background: "#e9d", color:"#fff"}}
      icon={<WorkIcon/>}
      > 
      <h3 className='vertical-timeline-element-title'>
        Full Stack developer - Match & Catch
      </h3>
      <h4 className='vertical-timeline-element-title'>
        Toronto, Ontario
      </h4>
      <p> - Designed and created Car dealer Database, using Elastic Beanstalk with Amazon RDS. <br></br> - Developed the web-based applications using front end technologies such as HTML, CSS, JavaScript and React.
      </p>
      </VerticalTimelineElement>

    </VerticalTimeline>
  </div>
  ) ;
}
export default Experience;