import Proj1 from "../assets/sch.png";
import Proj2 from "../assets/tweeter.png";
import Proj3 from "../assets/tinyapp.png";

export const ProjectList = [
{
  name: "Scheduler",
  image: Proj1,
  skills: "React.JS, Axios, Normalize, StoryBook",
  link: "https://github.com/devRyanChoi/scheduler",
},
{
  name: "Tweeter",
  image: Proj2,
  skills: "HTML, CSS, JavaScript, jQuery, NodeJS, Express",
  link: "https://github.com/devRyanChoi/tweeter"
},
{
  name: "Tiny App",
  image: Proj3,
  skills: "HTML, CSS, BootStrap, EJS, Node.JS, Express, ",
  link: "https://github.com/devRyanChoi/tinyapp"
},
];